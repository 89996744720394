<template>
  <div class="mycreated">
    <div class="mycreated_center">
      <!-- <h1>建立圈子</h1> -->
      <header>
        <p>建立圈子管理</p>
        <el-button @click="createcircle" type="primary" size="mini">建立新教研圈</el-button>
      </header>
      <nav>
        <el-form ref="resourceRef" :model="resourceForm" :inline="true">
          <el-form-item label="教研圈名称" prop="circleName">
            <el-input v-model="resourceForm.circleName" @change="querylist" size="mini" clearable></el-input>
          </el-form-item>
          <el-form-item label="学科类型" prop="subjectTypeId">
            <el-select v-model="resourceForm.subjectTypeId" @change="changeshuject" size="mini" clearable>
              <el-option v-for="item in subjectypedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科" prop="subjectId">
            <el-select v-model="resourceForm.subjectId" size="mini" clearable>
              <el-option v-for="item in subjectdata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学段" prop="periodId">
            <el-select v-model="resourceForm.periodId" @change="changeperiod" size="mini" clearable>
              <el-option v-for="item in perioddata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级" prop="gradeId">
            <el-select v-model="resourceForm.gradeId" size="mini" clearable>
              <el-option v-for="item in gradedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-form-item prop="startTime" style="width:150px">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.startTime"
                style="width: 100%;"
                size="mini"
                clearable
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item prop="endTime" style="width:150px">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.endTime"
                style="width: 100%;"
                size="mini"
                clearable
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-form-item>
          <el-form-item label="审核状态" prop="auditStatus" v-if="this.activeName=='first'">
            <el-select v-model="resourceForm.auditStatus" placeholder="请选择" size="mini">
              <el-option v-for="item in auditdata" :key="item.id" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item>
            <el-button type="primary" @click="querylist" size="mini">查询</el-button>
            <el-button  @click="resetfrom('resourceRef')" size="mini">重置</el-button>
          </el-form-item>-->
        </el-form>
        <div class="nav_btns">
          <el-button type="primary" @click="querylist" size="mini">查询</el-button>
          <el-button @click="resetfrom('resourceRef')" size="mini">重置</el-button>
        </div>
      </nav>
      <main>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="未上线的圈子" name="first">
            <none :cirdata="resTabDatas" :curp="curpage" :psize="pageSize" @submit="submeth"></none>
          </el-tab-pane>
          <el-tab-pane label="已上线的圈子" name="second">
            <yet :cirdata="resTabDatas" :curps="curpage" :psizes="pageSize"></yet>
          </el-tab-pane>
        </el-tabs>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="curpage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="total"
        ></el-pagination>
      </main>
    </div>
  </div>
</template>
<script>
import yet from "./component/yet.vue";
import none from "./component/none.vue";
export default {
  data() {
    return {
      activeName: "first",
      // 教研圈列表
      resourceForm: {
        circleName: null,
        subjectTypeId: null,
        subjectId: null,
        periodId: null,
        gradeId: null,
        startTime: null,
        endTime: null,
        auditStatus: null,
      },
      //学科类型
      subjectypedata: null,
      // 学科
      subjectdata: null,
      // 学段
      perioddata: null,
      // 年级
      gradedata: null,
      //审核状态
      auditdata: null,

      // 表格数据
      resTabData: null,
      // 分页
      curpage: 1, //当前页数
      pageSize: 10, //当前条数
      total: 100, //总数
    };
  },
  created() {
    // this.datagreement = agereedata; //协议内容
    this.querySubjectType(); //获取课程类型
    this.querySubject(0); //获取学科信息
    this.queryPeriod(); //获取学段
    this.queryGrade(0); //获取年级信息
    this.querystatus(); //获取审核状态
    // 获取教研圈列表
    this.queryMyNoOnlineCircle();
    // this.queryMyOnlineCircle();
  },
  methods: {
    // 查询查询未上线的圈子
    async queryMyNoOnlineCircle() {
      // console.log(this.resourceForm, "resourceForm");
      const { data, status } = await this.$Http.queryMyNoOnlineCircle({
        // circleName : circleName , //圈子名称
        // subjectTypeId: subjectTypeId, //课程类型ID
        // subjectId: subjectId, //学科ID
        // periodId: periodId, //学段ID
        // gradeId: gradeId, //年级ID
        // startTime: startTime, //审核开始时间
        // endTime:endTime,//结束时间
        // auditStatus : auditStatus , //审核状态
        //
        ...this.resourceForm,
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
      });
      if (status == 200) {
        // console.log(data, status, "获取未上线的圈子数据");
        this.resTabData = data;
        // this.$refs["resourceRef"].resetFields();
      }
    },
    // 查询查询上线的圈子
    async queryMyOnlineCircle() {
      // console.log(this.resourceForm, "resourceForm");
      const { data, status } = await this.$Http.queryMyOnlineCircle({
        // circleName : circleName , //圈子名称
        // subjectTypeId: subjectTypeId, //课程类型ID
        // subjectId: subjectId, //学科ID
        // periodId: periodId, //学段ID
        // gradeId: gradeId, //年级ID
        // startTime: startTime, //审核开始时间
        // endTime:endTime,//结束时间
        // auditStatus : auditStatus , //审核状态
        //
        ...this.resourceForm,
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
      });
      if (status == 200) {
        // console.log(data, status, "获取已经上线的圈子数据");
        this.resTabData = data;
        // this.$refs["resourceRef"].resetFields();
      }
    },

    // 获取课程类型
    async querySubjectType() {
      await this.$Http.querySubjectType().then((res) => {
        // console.log(res, "获取课程类型");
        if (res.status == 200) {
          this.subjectypedata = res.data;
        }
      });
    },
    // 获取学科信息
    async querySubject(id) {
      // console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.querySubject({
        idList: ids,
      });
      if (status == 200) {
        // console.log(data, status, "获取学科");
        this.subjectdata = data;
      }
    },
    // 获取学段
    async queryPeriod() {
      await this.$Http.queryPeriod().then((res) => {
        // console.log(res, "获取学段");
        if (res.status == 200) {
          this.perioddata = res.data;
        }
      });
    },
    // 获取年级信息
    async queryGrade(id) {
      // console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.queryGrade({
        idList: ids,
      });
      if (status == 200) {
        // console.log(data, status, "获取年级信息");
        this.gradedata = data;
      }
    },
    //审核状态
    async querystatus() {
      // console.log("获取审核状态");
      let aa = [
        // {
        //   id: 0,
        //   name: "未提交审核",
        //   value: "0",
        // },
        // {
        //   id: 1,
        //   name: "审核不通过",
        //   value: "1",
        // },
        {
          id: 2,
          name: "提交待审核",
          value: "2",
        },
        {
          id: 3,
          name: "审核通过",
          value: "3",
        },
      ];
      this.auditdata = aa;
    },
    // 学科类型的值得变化
    changeshuject(val) {
      console.log(val, "学科类型", val, "学段", !val, typeof val);
      this.resourceForm.subjectId = null;
      if (!val) {
        this.querySubject(0);
      } else {
        this.querySubject(val);
      }
    },
    // 学段的值得变化
    changeperiod(val) {
      console.log(val, "学段", !val, typeof val);
      this.resourceForm.gradeId = null;
      if (!val) {
        this.queryGrade(0);
      } else {
        this.queryGrade(val);
      }
    },
    // 查询
    querylist() {
      // console.log(this.activeName);
      if (this.activeName == "first") {
        this.queryMyNoOnlineCircle();
      } else if (this.activeName == "second") {
        this.queryMyOnlineCircle();
      }
    },
    // 重置
    resetfrom(formName) {
      // console.log(this.$refs, "重置");
      this.$refs[formName].resetFields();
      this.querySubject(0); //获取学科信息
      this.queryGrade(0); //获取年级信息
      if (this.activeName == "first") {
        this.queryMyNoOnlineCircle();
      } else if (this.activeName == "second") {
        this.queryMyOnlineCircle();
      }
    },

    // 子组件传递
    submeth(data) {
      // console.log(data, "组件传递");
      if (data) {
        this.queryMyNoOnlineCircle();
      } else {
        this.queryMyOnlineCircle();
      }
    },

    // 上传课程
    createcircle() {
      // console.log(this.agreementDo, "上传课程");
      this.$router.push({
        path: "/createcircle",
      });
    },
    // 切换tabs
    handleClick(tab, event) {
      // console.log(tab, event);
      this.resetfrom("resourceRef");
      if (tab.name == "first") {
        this.pageSize = 10;
        this.curpage = 1;
        this.queryMyNoOnlineCircle();
      } else if (tab.name == "second") {
        this.pageSize = 10;
        this.curpage = 1;
        this.queryMyOnlineCircle();
      }
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      if (this.activeName == "first") {
        this.queryMyNoOnlineCircle();
      } else if (this.activeName == "second") {
        this.queryMyOnlineCircle();
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.curpage = val;
      if (this.activeName == "first") {
        this.queryMyNoOnlineCircle();
      } else if (this.activeName == "second") {
        this.queryMyOnlineCircle();
      }
    },
  },
  computed: {
    resTabDatas() {
      // console.log(this.resTabData, "this.resTabData");
      let a = this.resTabData;
      this.total = 0;
      if (a !== null) {
        let aa = this.resTabData.list;
        this.total = this.resTabData.total;
        if (aa[0].periodName.join === undefined) {
          return aa;
        }
        for (let x = 0; x < aa.length; x++) {
          aa[x].periodName = aa[x].periodName.join("/");
          aa[x].gradeName = aa[x].gradeName.join("/");
          // aa[x].subjectName = aa[x].subjectName.join("/");
          // aa[x].subjectType = aa[x].subjectType.join("/");
        }
        return aa;
      }
    },
  },
  components: {
    yet,
    none,
  },
};
</script>
<style lang="scss" scoped>
.mycreated {
  .mycreated_center {
    header {
      display: flex;
      justify-content: space-between;
      // width: 1688px;
      height: 70px;
      background: #ffffff;
      padding: 0 20px;
      p {
        // width: 108px;
        height: 70px;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 600;
        color: #141516;
        line-height: 70px;
      }
      .el-button {
        height: 30px;
        margin: 20px 0 0 0;
      }
    }
    nav {
      margin-top: 10px;
      background-color: #fff;
      padding: 15px;
      // .el-form-item{
      //   width: 230px;
      // }
      .el-input {
        width: 150px;
      }
      .el-select {
        width: 120px;
      }
      .nav_btns {
        display: flex;
        justify-content: flex-end;
      }
    }
    main {
      background-color: #fff;
      padding: 0 20px;
      margin-top: 10px;
      .el-pagination {
        display: flex;
        justify-content: center;
        padding: 20px 0;
      }
    }
  }
}
</style>